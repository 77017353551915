.discussion-spotlight-container {
  height: 150px;
  overflow: hidden;
  cursor: pointer;

  .discussion-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .spotlight-avatar {
    border-color: var(--bgColor-default, var(--color-canvas-default)) !important;
    // stylelint-disable-next-line primer/borders
    border-width: 3px !important;
  }
}

.discussions-spotlight-wrapper:first-of-type {
  padding-left: 0 !important;
}

.discussion-spotlight-color-container {
  width: 210px;
  height: 80px;
}

.discussion-spotlight-color-container .discussion-spotlight-gradient {
  width: 35px;
  height: 35px;
}

.discussion-spotlight-checkmark {
  position: absolute;
  display: flex;
  width: 23px;
  height: 23px;
  // stylelint-disable-next-line primer/spacing
  margin-top: 6px;
  // stylelint-disable-next-line primer/spacing
  margin-left: 6px;
  // stylelint-disable-next-line primer/colors
  background: rgb(7 7 7 / 0.63);
}

//stylelint-disable-next-line selector-max-type
.discussion-spotlight-color-container input {
  //stylelint-disable-next-line selector-max-type
  &:not(:checked) ~ .discussion-spotlight-checkmark {
    display: none;
  }

  //stylelint-disable-next-line selector-max-type
  &:checked + .discussion-spotlight-checkmark::after {
    display: flex;
  }

  //stylelint-disable-next-line selector-max-type
  &:checked {
    // stylelint-disable-next-line primer/box-shadow
    box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em var(--borderColor-accent-muted, var(--color-accent-muted));
  }
}
